<template>
  <a-spin :spinning="spinningLoadingPage">
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        
        <b-link class="brand-logo">
          <a href="#" class="text-left mb-1"><b-img :src="require('@/assets/images/logo/bmkg_logo.png')" alt="logo" width="60px" height="60px" class="max-h-100px"/></a>
          <vuexy-logo />
          <!-- <h2 class="brand-text text-white ml-1">
            Selamat Datang si Sistem Pengelolaan Data BMKGSatu
          </h2> -->
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col
          lg="8"
          class="d-none d-lg-flex align-items-center p-5" 
          style="background: linear-gradient(180deg, rgba(8,102,189,1) 0%, rgba(31,144,249,1) 52%, rgba(228,237,241,1) 100%);"
        >
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"
            />
          </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Login-->
        <b-col
          lg="4"
          class="d-flex align-items-center auth-bg px-2 p-lg-5"
        >
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
          >
            <b-card-title
              class="mb-4 font-weight-bolder text-center text-dark"
              title-tag="h2"
            >
              Login
            </b-card-title>
            
            <!-- <b-card-text class="mb-2">
              Please sign-in to your account and start the adventure
            </b-card-text> -->

            <!-- <b-alert
              variant="primary"
              show
            >
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
                </p>
                <p>
                  <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
                </p>
              </div>
              <feather-icon
                v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
                icon="HelpCircleIcon"
                size="18"
                class="position-absolute"
                style="top: 10; right: 10;"
              />
            </b-alert> -->

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- email -->
                <b-form-group
                  label="Username"
                  class="font-weight-bolder"
                  label-for="login-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="Station Id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group
                  label="Password"
                  class="font-weight-bolder"
                  label-for="login-password">
                  
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              
                <!-- checkbox -->
                <!-- <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Remember Me
                  </b-form-checkbox>
                </b-form-group> -->
                <b-form-group>
                  
                  <div class="col-sm-12 col-xl-12 col-md-12" style="margin-left: -14px;">
                    <vue-recaptcha ref="recaptcha"
                      @verify="onVerify" :sitekey="sitekey" :loadRecaptchaScript="true">
                    </vue-recaptcha>
                  </div>
                </b-form-group>
                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="success"
                  block
                  :disabled="invalid"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <b-card-text class="text-center mt-2">
              <span>New on our platform? </span>
              <b-link :to="{name:'auth-register'}">
                <span>&nbsp;Create an account</span>
              </b-link>
            </b-card-text> -->

            <!-- divider -->
            <div class="divider my-2">
              <div class="divider-text">
                
              </div>
            </div>

            <!-- social buttons -->
            <!-- <div class="auth-footer-btn d-flex justify-content-center">
              <b-button
                variant="facebook"
                href="javascript:void(0)"
              >
                <feather-icon icon="FacebookIcon" />
              </b-button>
              <b-button
                variant="twitter"
                href="javascript:void(0)"
              >
                <feather-icon icon="TwitterIcon" />
              </b-button>
              <b-button
                variant="google"
                href="javascript:void(0)"
              >
                <feather-icon icon="MailIcon" />
              </b-button>
              <b-button
                variant="github"
                href="javascript:void(0)"
              >
                <feather-icon icon="GithubIcon" />
              </b-button>
            </div> -->
          </b-col>
        </b-col>
      <!-- /Login-->
      </b-row>
    </div>
  </a-spin>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueRecaptcha from 'vue-recaptcha';
import Helper from "@/api/helper"
import User from "@/api/userservice"
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const site_key = process.env.VUE_APP_GOOGLE_CAPTCHA_KEY
    return {
      sitekey : site_key,
      status: '',
      // password: 'admin',
      // userEmail: 'admin@demo.com',
      password: '',//'password123',
      userEmail: '',//'admin1',
      sideImg: require('@/assets/images/pages/login-bmkgsoft.svg'),
      robot: false,
      // validation rules
      required,
      email,
      spinningLoadingPage: false
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted(){
    // const keykey = process.env.VUE_APP_GOOGLE_CAPTCHA_KEY
    // console.log("site key : ",keykey)
  },
  methods: {
    onVerify: function (response) {
      if (response) this.robot = true;
    },
    
    login() {
      
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          if(this.robot){
            let username = this.userEmail
            let password = this.password
            this.spinningLoadingPage = true
            Helper.loginTemp(username, password)
              .then(response => {
                  console.log('responsenya: ', response);
                  if (response.status == 201 || response.status == 200 || response.status == 204) {
                    const tokennya  = response.data.token
                    // this.token_helper = tokennya

                    // let isMember = ( username == 'root' || username == "admin1" ) ? false : true
                    // localStorage.setItem('userLogin', JSON.stringify({
                    //   id:1,
                    //   username: username,
                    //   password: password,
                    //   fullName: username,
                    //   avatar: "/bmkgsatu/img/13-small.d796bffd.png",
                    //   role: isMember ? "member":"admin",
                    //   // grole: response.data.role,
                    //   // listperm: response.data.lisperm
                    // }))

                    // let userData = {
                    //   id:1,
                    //   avatar: "/bmkgsatu/img/13-small.d796bffd.png",
                    //   fullName: username,
                    //   role: "admin",
                    //   username: username
                    // }
                    useJwt.setToken(tokennya)
                    useJwt.setRefreshToken(tokennya)
                    // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', 5)
                    // localStorage.setItem('userData', JSON.stringify(userData))
                    // this.$ability.update([{action: 'manage', subject: 'all'}])
                    // this.$router.replace('/');

                    if(username != 'root')  {
                      const promise = new Promise( async(resolve, reject) => {
                        
                        // const {data} = await User.getDetailUser("/users/"+username)
                        let createLog = await User.createLoginLogActivity(username)
                        const {data} = await User.getListUserInfo()
                        // console.log('login', data)
                        let id = data.id
                        let isMember = ( id == "root" ) ? false : true
                        let roleName = ( id == "root" ) ? "admin" : ""
                        let listperm = []
                        let fullname = ""
                        if(isMember) {
                          if (data.roles) {
                            for (let key in data.roles) {
                              if (key.includes("bmkgsatu")){
                                roleName = key.split('.')[1]
                                break
                              }
                            }                            
                          }

                          if (data.lispermrole.length > 0) {
                            listperm = data.lispermrole
                          }

                          if (data.name != "") {
                            fullname = data.name
                          }
                          else {
                            fullname = username
                          }
                        }

                        let groupIds = Helper.getGroupIds(data.groups)
                        localStorage.setItem('stateWebsite', 'login')
                        localStorage.setItem('userLogin', JSON.stringify({
                          id:username,
                          username: username,
                          // password: password,
                          fullName: fullname,
                          email: data.email,
                          avatar: "/bmkgsatu/img/13-small.d796bffd.png",
                          role: isMember ? roleName : "admin",
                          groupIds: groupIds,
                          listperm: listperm
                        }))

                        let roles = Helper.getMenuRoles(listperm)

                        
                        localStorage.setItem('userRoles', roles)
                        // console.log('sudah set item')

                        resolve(data)
                      }) 

                      promise.then((data) => {

                        setTimeout(() => {
                          useJwt.login({
                            email: 'admin@demo.com',//this.userEmail,
                            password: 'admin'//this.password,
                          })
                            .then(response => {
                              this.spinningLoadingPage = false
                              console.log(response)
                              const { userData } = response.data
                              // useJwt.setToken(tokennya)
                              // useJwt.setRefreshToken(tokennya)
                              localStorage.setItem('userData', JSON.stringify(userData))
                              this.$ability.update(userData.ability)

                              // ? This is just for demo purpose as well.
                              // ? Because we are showing eCommerce app's cart items count in navbar
                              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

                              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                              this.$router.replace(getHomeRouteForLoggedInUser(userData.role), this.$router.go(0))
                                .then(() => {
                                  
                                })

                              this.$router.go(this.$router.currentRoute)
                              
                            })
                            .catch(error => {
                              this.spinningLoadingPage = false
                              this.$refs.loginForm.setErrors(error.response.data.error)
                            })
                        }, 1000);
                        
                      })
                    }
                    else {
                      
                      localStorage.setItem('userLogin', JSON.stringify({
                          id:"root",
                          username: username,
                          // password: password,
                          fullName: username,
                          email: '',
                          avatar: "/bmkgsatu/img/13-small.d796bffd.png",
                          role: "admin",
                          grole: null,
                          listperm: null
                        }))

                      localStorage.setItem('stateWebsite', 'login')

                      setTimeout(() => {
                        useJwt.login({
                            email: 'admin@demo.com',//this.userEmail,
                            password: 'admin'//this.password,
                          })
                          .then(response => {
                            console.log(response)
                            const { userData } = response.data
                            // useJwt.setToken(tokennya)
                            // useJwt.setRefreshToken(tokennya)
                            localStorage.setItem('userData', JSON.stringify(userData))
                            this.$ability.update(userData.ability)

                            // ? This is just for demo purpose as well.
                            // ? Because we are showing eCommerce app's cart items count in navbar
                            this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
                            this.spinningLoadingPage = false
                            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                            this.$router.replace(getHomeRouteForLoggedInUser(userData.role), this.$router.go(0))
                              .then(() => {
                                // this.$router.go(this.$router.currentRoute)
                              })

                            
                          })
                          .catch(error => {
                            this.spinningLoadingPage = false
                            this.$refs.loginForm.setErrors(error.response.data.error)
                          })
                      }, 1000);
                       
                    }
                  }
              })
              .catch(error => {
                this.spinningLoadingPage = false
                console.log('error :', error);
                if (error.response.status == 401) {
                  this.$swal({
                    title: "Gagal Login. Unauthorized!",
                    text: "Username dan Password anda salah",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                }
                else {
                  this.$swal({
                    title: "Login!",
                    text: "" + error.response.status,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                } 

              });

            // useJwt.login({
            //   email: this.userEmail,
            //   password: this.password,
            // })
            //   .then(response => {
            //     console.log(response)
            //     const { userData } = response.data
            //     useJwt.setToken(response.data.token)
            //     useJwt.setRefreshToken(response.data.refreshToken)
            //     localStorage.setItem('userData', JSON.stringify(userData))
            //     this.$ability.update(userData.ability)

            //     // ? This is just for demo purpose as well.
            //     // ? Because we are showing eCommerce app's cart items count in navbar
            //     this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

            //     // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
            //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            //       .then(() => {
            //         this.$toast({
            //           component: ToastificationContent,
            //           position: 'top-right',
            //           props: {
            //             title: `Welcome ${userData.fullName || userData.username}`,
            //             icon: 'CoffeeIcon',
            //             variant: 'success',
            //             text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //           },
            //         })
            //       })
            //   })
            //   .catch(error => {
            //     this.$refs.loginForm.setErrors(error.response.data.error)
            //   })      
          }else{
            this.$swal({
              title: 'Error!',
              text: ' Please Complete The Capcha',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }          
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
